import React from "react";
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "../../Store/Slices/UserApiSlice";
import {
  Form,
  Button,
  Card,
  Typography,
  Skeleton,
  message,
  Select,
} from "antd";

import { useGetDashboardQuery } from "../../Store/Slices/DashboardApiSlice";

const { Title } = Typography;
const { Option } = Select;

const UserProfile = () => {
  const { data, isLoading }: any = useGetUserProfileQuery();
  const [updateProfile, { isLoading: updateLoading }] =
    useUpdateUserProfileMutation();
  const { refetch } = useGetDashboardQuery();

  const [form] = Form.useForm();

  // Update form when data is loaded
  React.useEffect(() => {
    if (data?.data) {
      form.setFieldsValue({
        ugDegree: data.data.ug_degree,
        ugTier: data.data.ug_tier,
        skills: data.data.skills,
      });
    }
  }, [data, form]);

  const handleSubmit = async (values: any) => {
    try {
      await updateProfile(values).unwrap();
      refetch();
      message.success("Profile updated successfully!");
    } catch (err) {
      message.error("Failed to update profile");
      console.error("Failed to update profile:", err);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <Skeleton active paragraph={{ rows: 4 }} />
      </Card>
    );
  }

  return (
    <Card
      title={
        <Title level={4} style={{ margin: 0 }}>
          User Profile
        </Title>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={data?.data}
      >
        <Form.Item
          label="UG Degree"
          name="ugDegree"
          rules={[{ required: true, message: "Please input your UG degree!" }]}
        >
          <Select>
            <Option value="BE">BE</Option>
            <Option value="BA">BA</Option>
            <Option value="BArch">BArch</Option>
            <Option value="BBA">BBA</Option>
            <Option value="BBM">BBM</Option>
            <Option value="BCA">BCA</Option>
            <Option value="BCom">BCom</Option>
            <Option value="BDes">BDes</Option>
            <Option value="BEd">BEd</Option>
            <Option value="BFA">BFA</Option>
            <Option value="BHMS">BHMS</Option>
            <Option value="BHM">BHM</Option>
            <Option value="BL">BL</Option>
            <Option value="BPharm">BPharm</Option>
            <Option value="BSc">BSc</Option>
            <Option value="BTech">BTech</Option>
            <Option value="BVSc">BVSc</Option>
            <Option value="BAMS">BAMS</Option>
            <Option value="MBBS">MBBS</Option>
            <Option value="LLB">LLB</Option>

            {/* Add more degree options as needed */}
          </Select>
        </Form.Item>

        <Form.Item
          label="UG Tier"
          name="ugTier"
          rules={[{ required: true, message: "Please select your UG tier!" }]}
        >
          <Select>
            <Option value="1">Tier 1</Option>
            <Option value="2">Tier 2</Option>
            <Option value="3">Tier 3</Option>
            <Option value="4">Tier 4</Option>
            <Option value="5">Tier 5</Option>
            <Option value="6">Tier 6</Option>
            <Option value="7">Tier 7</Option>
            <Option value="8">Tier 8</Option>
            <Option value="9">Tier 9</Option>
            <Option value="10">Tier 10</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Skills"
          name="skills"
          rules={[
            { required: true, message: "Please select at least one skill!" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select your skills"
            showSearch
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {/* Technical Skills */}
            <Option value="javascript">JavaScript</Option>
            <Option value="python">Python</Option>
            <Option value="java">Java</Option>
            <Option value="csharp">C#</Option>
            <Option value="cpp">C++</Option>
            <Option value="ruby">Ruby</Option>
            <Option value="php">PHP</Option>
            <Option value="swift">Swift</Option>
            <Option value="kotlin">Kotlin</Option>
            <Option value="rust">Rust</Option>
            <Option value="golang">Go</Option>
            <Option value="typescript">TypeScript</Option>
            <Option value="react">React</Option>
            <Option value="angular">Angular</Option>
            <Option value="vue">Vue.js</Option>
            <Option value="node">Node.js</Option>
            <Option value="django">Django</Option>
            <Option value="flask">Flask</Option>
            <Option value="spring">Spring</Option>
            <Option value="docker">Docker</Option>
            <Option value="kubernetes">Kubernetes</Option>
            <Option value="aws">AWS</Option>
            <Option value="azure">Azure</Option>
            <Option value="gcp">Google Cloud</Option>

            {/* Data Science & Analytics */}
            <Option value="machine_learning">Machine Learning</Option>
            <Option value="deep_learning">Deep Learning</Option>
            <Option value="nlp">Natural Language Processing</Option>
            <Option value="computer_vision">Computer Vision</Option>
            <Option value="data_analysis">Data Analysis</Option>
            <Option value="statistics">Statistics</Option>
            <Option value="r_programming">R Programming</Option>
            <Option value="tensorflow">TensorFlow</Option>
            <Option value="pytorch">PyTorch</Option>
            <Option value="tableau">Tableau</Option>
            <Option value="power_bi">Power BI</Option>

            {/* Business & Management */}
            <Option value="project_management">Project Management</Option>
            <Option value="agile">Agile Methodology</Option>
            <Option value="scrum">Scrum</Option>
            <Option value="business_strategy">Business Strategy</Option>
            <Option value="risk_management">Risk Management</Option>
            <Option value="change_management">Change Management</Option>
            <Option value="stakeholder_management">
              Stakeholder Management
            </Option>
            <Option value="business_analysis">Business Analysis</Option>
            <Option value="strategic_planning">Strategic Planning</Option>

            {/* Marketing */}
            <Option value="digital_marketing">Digital Marketing</Option>
            <Option value="seo">SEO</Option>
            <Option value="sem">SEM</Option>
            <Option value="social_media_marketing">
              Social Media Marketing
            </Option>
            <Option value="content_marketing">Content Marketing</Option>
            <Option value="email_marketing">Email Marketing</Option>
            <Option value="brand_management">Brand Management</Option>
            <Option value="market_research">Market Research</Option>
            <Option value="marketing_analytics">Marketing Analytics</Option>

            {/* Finance */}
            <Option value="financial_analysis">Financial Analysis</Option>
            <Option value="investment_banking">Investment Banking</Option>
            <Option value="corporate_finance">Corporate Finance</Option>
            <Option value="financial_modeling">Financial Modeling</Option>
            <Option value="risk_assessment">Risk Assessment</Option>
            <Option value="portfolio_management">Portfolio Management</Option>
            <Option value="accounting">Accounting</Option>
            <Option value="taxation">Taxation</Option>
            <Option value="blockchain">Blockchain</Option>
            <Option value="cryptocurrency">Cryptocurrency</Option>

            {/* Design */}
            <Option value="ui_design">UI Design</Option>
            <Option value="ux_design">UX Design</Option>
            <Option value="graphic_design">Graphic Design</Option>
            <Option value="web_design">Web Design</Option>
            <Option value="product_design">Product Design</Option>
            <Option value="adobe_photoshop">Adobe Photoshop</Option>
            <Option value="adobe_illustrator">Adobe Illustrator</Option>
            <Option value="figma">Figma</Option>
            <Option value="sketch">Sketch</Option>

            {/* Healthcare */}
            <Option value="clinical_research">Clinical Research</Option>
            <Option value="patient_care">Patient Care</Option>
            <Option value="healthcare_management">Healthcare Management</Option>
            <Option value="medical_coding">Medical Coding</Option>
            <Option value="pharmacy">Pharmacy</Option>
            <Option value="public_health">Public Health</Option>
            <Option value="telemedicine">Telemedicine</Option>

            {/* Education */}
            <Option value="teaching">Teaching</Option>
            <Option value="curriculum_development">
              Curriculum Development
            </Option>
            <Option value="educational_technology">
              Educational Technology
            </Option>
            <Option value="instructional_design">Instructional Design</Option>
            <Option value="e_learning">E-Learning</Option>
            <Option value="special_education">Special Education</Option>

            {/* Soft Skills */}
            <Option value="leadership">Leadership</Option>
            <Option value="communication">Communication</Option>
            <Option value="problem_solving">Problem Solving</Option>
            <Option value="critical_thinking">Critical Thinking</Option>
            <Option value="teamwork">Teamwork</Option>
            <Option value="time_management">Time Management</Option>
            <Option value="negotiation">Negotiation</Option>
            <Option value="conflict_resolution">Conflict Resolution</Option>
            <Option value="emotional_intelligence">
              Emotional Intelligence
            </Option>

            {/* Languages */}
            <Option value="english">English</Option>
            <Option value="spanish">Spanish</Option>
            <Option value="french">French</Option>
            <Option value="german">German</Option>
            <Option value="chinese">Chinese</Option>
            <Option value="japanese">Japanese</Option>
            <Option value="arabic">Arabic</Option>
            <Option value="russian">Russian</Option>

            {/* Operations */}
            <Option value="supply_chain">Supply Chain Management</Option>
            <Option value="logistics">Logistics</Option>
            <Option value="quality_control">Quality Control</Option>
            <Option value="inventory_management">Inventory Management</Option>
            <Option value="lean_six_sigma">Lean Six Sigma</Option>
            <Option value="process_improvement">Process Improvement</Option>
            <Option value="operations_management">Operations Management</Option>
          </Select>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={updateLoading}
        >
          Save changes
        </Button>
      </Form>
    </Card>
  );
};

export default UserProfile;
